<template>
  <RecordViewWrapper>
    <sdPageHeader title="Rekap Pasokan Mitra">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create permintaan internal rekap pasokan mitra')"
        >
          <router-link to="/internal/rekap-pasokan-mitra-add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :pagination="false" :dataSource="dataSource" :columns="columns.data" />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove rekap pasokan mitra {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const columns = reactive({
  data: [],
});

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = new Date();
    const thisYear = dateObj.getUTCFullYear();
    const lastYear = dateObj.getUTCFullYear() - 1;
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      month: '',
      year: null,
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'rekap-pasokan-mitra').then(() => {
        columns.data = [];

        columns.data = [
          {
            title: 'Mitra',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Kode Mitra',
            dataIndex: 'code',
            key: 'code',
          },
        ];

        months.forEach((month, key) => {
          columns.data.push({
            title: month,
            children: [
              {
                title: lastYear,
                children: [
                  {
                    title: 'Konfirmasi',
                    dataIndex: 'konfirmasi_ly_' + key,
                    key: 'konfirmasi_ly_' + key,
                    align: 'right',
                  },
                  {
                    title: 'Realisasi',
                    dataIndex: 'realisasi_ly_' + key,
                    key: 'realisasi_ly_' + key,
                    align: 'right',
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status_ly_' + key,
                    key: 'status_ly_' + key,
                  },
                  {
                    title: '',
                    dataIndex: 'action_ly_' + key,
                    key: 'action_ly_' + key,
                  },
                ],
              },
              {
                title: thisYear,
                children: [
                  {
                    title: 'Konfirmasi',
                    dataIndex: 'konfirmasi_' + key,
                    key: 'konfirmasi_' + key,
                    align: 'right',
                  },
                  {
                    title: 'Realisasi',
                    dataIndex: 'realisasi_' + key,
                    key: 'realisasi_' + key,
                    align: 'right',
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status_' + key,
                    key: 'status_' + key,
                  },
                  {
                    title: '',
                    dataIndex: 'action_' + key,
                    key: 'action_' + key,
                  },
                ],
              },
            ],
          });
        });
      });
    };

    const handleDelete = (id, month, year, mitra_pemasok) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus rekap pasokan mitra ${months[month - 1]} ${year} ${mitra_pemasok}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'rekap-pasokan-mitra',
            getData: () => {
              dispatch('axiosCrudGetData', 'rekap-pasokan-mitra');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const { name, code, dataPasokans, dataPasokanLYs, is_total } = data;

            const values = {
              key: key + 1,
              name,
              code,
            };

            if (dataPasokans && dataPasokans.length) {
              if (is_total) {
                dataPasokans.forEach(dl => {
                  values['konfirmasi_' + (dl.month - 1)] = dl.konfirmasi;
                  values['realisasi_' + (dl.month - 1)] = dl.realisasi;

                  values['konfirmasi_ly_' + (dl.month - 1)] = dl.konfirmasi_ly;
                  values['realisasi_ly_' + (dl.month - 1)] = dl.realisasi_ly;
                });
              } else {
                dataPasokans.forEach(dl => {
                  let color;

                  switch (dl.status) {
                    case 'CRTD':
                      color = 'blue';
                      break;
                    case 'UPDT':
                      color = 'orange';
                      break;
                    case 'REL':
                      color = 'green';
                      break;
                    case 'UNREL':
                      color = 'volcano';
                      break;
                    case 'DEL':
                      color = 'red';
                      break;
                  }

                  values['konfirmasi_' + (dl.month - 1)] = dl.konfirmasi;
                  values['realisasi_' + (dl.month - 1)] = dl.realisasi;

                  values['status_' + (dl.month - 1)] = <a-tag color={color}>{dl.status}</a-tag>;

                  let update = checkPermission('update asdf') ? (
                    <router-link class="edit" to={`/internal/rekap-pasokan-mitra-edit/${dl.id}`}>
                      <sdFeatherIcons type="edit" size={14} title="Ubah" />
                    </router-link>
                  ) : null;

                  let approve = checkPermission('approve asdf') ? (
                    <a class="edit" onClick={() => handleApprove(dl.id, dl.month, dl.year)}>
                      <sdFeatherIcons type="check-square" size={14} title="Approve" />
                    </a>
                  ) : null;

                  let del = checkPermission('delete asdf') ? (
                    <router-link class="delete" onClick={() => handleDelete(dl.id, dl.month, dl.year, name)} to="#">
                      <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                    </router-link>
                  ) : null;

                  let unapprove = checkPermission('approve asdf') ? (
                    <a class="edit" onClick={() => handleUnapprove(dl.id, dl.month, dl.year)}>
                      <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
                    </a>
                  ) : null;

                  values['action_' + (dl.month - 1)] =
                    dl.status != 'REL' ? (
                      <div>
                        {update}
                        {approve}
                        {del}
                      </div>
                    ) : (
                      { unapprove }
                    );
                });
              }
            }

            if (dataPasokanLYs && dataPasokanLYs.length) {
              dataPasokanLYs.forEach(dl => {
                let color;

                switch (dl.status) {
                  case 'CRTD':
                    color = 'blue';
                    break;
                  case 'UPDT':
                    color = 'orange';
                    break;
                  case 'REL':
                    color = 'green';
                    break;
                  case 'UNREL':
                    color = 'volcano';
                    break;
                  case 'DEL':
                    color = 'red';
                    break;
                }

                values['konfirmasi_ly_' + (dl.month - 1)] = dl.konfirmasi;
                values['realisasi_ly_' + (dl.month - 1)] = dl.realisasi;

                values['status_ly_' + (dl.month - 1)] = <a-tag color={color}>{dl.status}</a-tag>;

                let update_ly = checkPermission('update asdf') ? (
                  <router-link class="edit" to={`/internal/rekap-pasokan-mitra-edit/${dl.id}`}>
                    <sdFeatherIcons type="edit" size={14} title="Ubah" />
                  </router-link>
                ) : null;

                let approve_ly = checkPermission('approve asdf') ? (
                  <a class="edit" onClick={() => handleApprove(dl.id, dl.month, dl.year)}>
                    <sdFeatherIcons type="check-square" size={14} title="Approve" />
                  </a>
                ) : null;

                let del_ly = checkPermission('delete asdf') ? (
                  <router-link class="delete" onClick={() => handleDelete(dl.id, dl.month, dl.year, name)} to="#">
                    <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                  </router-link>
                ) : null;

                let unapprove_ly = checkPermission('approve asdf') ? (
                  <a class="edit" onClick={() => handleUnapprove(dl.id, dl.month, dl.year)}>
                    <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
                  </a>
                ) : null;

                values['action_ly_' + (dl.month - 1)] =
                  dl.status != 'REL' ? (
                    <div>
                      {update_ly}
                      {approve_ly}
                      {del_ly}
                    </div>
                  ) : (
                    { unapprove_ly }
                  );
              });
            }

            return values;
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'rekap-pasokan-mitra', filter: formState.searchItem });
    };

    const handleApprove = (id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve rekap pasokan mitra ${months[month - 1]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-rekap-pasokan-mitra',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const handleUnapprove = (id, month, year) => {
      formModalState.id = id;
      modalState.month = months[month - 1];
      modalState.year = year;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-rekap-pasokan-mitra/' + formModalState.id, { alasan: formModalState.alasan }).then(
        () => {
          modalState.visible = false;
          modalState.isLoading = false;
          formModalState.id = null;
          formModalState.alasan = '';

          getData();
        },
      );
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      labelCol,
      wrapperCol,
      handleApprove,
      handleUnapprove,
      modalRules,
      modalState,
      formModalState,
      handleCancel,
      doUnapprove,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
